import React from 'react';
import styled from 'styled-components';
import { Step, Stepper, StepButton, StepContent } from 'material-ui/Stepper';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';

let QuestionArrayTw = [
  {
    title: 'Q. 甚麼是客製化上網卡?',
    description:
      '我們能提供您國家/天數/網速的選擇，讓您針對自身的需求，選擇最適合自己的上網方案。',
  },
  {
    title: 'Q. 請問我需要購買怎樣的方案比較適合我?',
    description:
      '我們有提供網路速度資訊予您參考，您可以先了解一下自身需求之後再訂購。',
  },
  {
    title: 'Q. 怎樣購買?',
    description:
      '您可以透過我們的網站下單，選擇國家/天數/網速，並使用信用卡/貨到付款等方式完成訂單，我們會在2-3個工作天將您所訂購的卡片寄出。',
  },
  {
    title: 'Q. 有怎樣的運送方式呢?',
    description:
      '我們提供超商取貨、郵寄與宅配的服務。您可於訂單完成後，選擇最適合您的收件方式。',
  },
  {
    title: 'Q. 使用信用卡付款安全嗎?',
    description:
      '我們是使用綠界信用卡付款系統。客戶經由綠界付款，我們不會保留客戶的信用卡資料。',
  },
  {
    title: 'Q. 請問天數如何計算',
    description:
      '天數計算是以台灣時間(GMT+8) 00:00:00至23:59:59為一天。不足一天亦以一天計算。',
  },
  {
    title: 'Q. 請問手機型號影響嗎?',
    description: '一般有支援4G的手機都能使用。',
  },
  {
    title: 'Q. 有提供什麼大小SIM卡?',
    description:
      '我們提供三合一SIM卡，有NANO，MICRO及NORMAL SIM，適用於大部份的手機。',
  },
  {
    title: 'Q. 卡片需要設定嗎?',
    description: '我們的卡片不用額外設定，插卡後開啟數據漫遊，就能連結網路。',
  },
  {
    title: 'Q. 手機需要開啟漫遊功能，會否產生漫遊費用?',
    description:
      '我們是數據漫遊卡，在插入SIM卡後需要開啟數據漫遊功能才能使用上網服務，當中不會產生任何漫遊費用。每次使用完我們的SIM卡，或換上其他SIM卡時，請記得關閉數據漫遊功能，以免產生不必要的漫遊費用。',
  },
  {
    title: 'Q. 目前客製化上網卡可在哪些國家使用?',
    description:
      '我們目前在XX國家或地區提供服務，未來將會繼續增加新的國家或地區。',
  },
  {
    title: 'Q. 請問有支援語音通話及簡訊服務嗎?',
    description: '我們只提供流動數據服務，並不支援通話與簡訊服務。',
  },
  {
    title: 'Q. 支援熱點分享嗎?',
    description:
      '可以使用手機熱點分享，但無法於電腦或WIFI機上使用。遵照公平使用原則，一般建議維持在2人使用。',
  },
  {
    title: 'Q. 數據流量有限制嗎？',
    description:
      '我們提供無限數據，但請留意公平使用原則，建議您於需要高流量時盡量使用免費WIFI。',
  },
  {
    title: 'Q. 卡片是否可以退換貨?',
    description:
      '因為我們是客製化的卡片，下單後一旦開始製作，恕不接受取消或是退貨，下單前請務必慎重考量自身需求。',
  },
  {
    title: 'Q. 如果我在使用上有任何技術上問題，可以怎樣解決？',
    description: '您可以加入我們的LINE@線上客服，我們會有專人為您解決問題',
  },
];
let QuestionArrayEn = [
  {
    title: 'Q. 甚麼是客製化上網卡? English',
    description:
      '我們能提供您國家/天數/網速的選擇，讓您針對自身的需求，選擇最適合自己的上網方案。',
  },
  {
    title: 'Q. 請問我需要購買怎樣的方案比較適合我?',
    description:
      '我們有提供網路速度資訊予您參考，您可以先了解一下自身需求之後再訂購。',
  },
  {
    title: 'Q. 怎樣購買?',
    description:
      '您可以透過我們的網站下單，選擇國家/天數/網速，並使用信用卡/貨到付款等方式完成訂單，我們會在2-3個工作天將您所訂購的卡片寄出。',
  },
  {
    title: 'Q. 有怎樣的運送方式呢?',
    description:
      '我們提供超商取貨、郵寄與宅配的服務。您可於訂單完成後，選擇最適合您的收件方式。',
  },
  {
    title: 'Q. 使用信用卡付款安全嗎?',
    description:
      '我們是使用綠界信用卡付款系統。客戶經由綠界付款，我們不會保留客戶的信用卡資料。',
  },
  {
    title: 'Q. 請問天數如何計算',
    description:
      '天數計算是以台灣時間(GMT+8) 00:00:00至23:59:59為一天。不足一天亦以一天計算。',
  },
  {
    title: 'Q. 請問手機型號影響嗎?',
    description: '一般有支援4G的手機都能使用。',
  },
  {
    title: 'Q. 有提供什麼大小SIM卡?',
    description:
      '我們提供三合一SIM卡，有NANO，MICRO及NORMAL SIM，適用於大部份的手機。',
  },
  {
    title: 'Q. 卡片需要設定嗎?',
    description: '我們的卡片不用額外設定，插卡後開啟數據漫遊，就能連結網路。',
  },
  {
    title: 'Q. 手機需要開啟漫遊功能，會否產生漫遊費用?',
    description:
      '我們是數據漫遊卡，在插入SIM卡後需要開啟數據漫遊功能才能使用上網服務，當中不會產生任何漫遊費用。每次使用完我們的SIM卡，或換上其他SIM卡時，請記得關閉數據漫遊功能，以免產生不必要的漫遊費用。',
  },
  {
    title: 'Q. 目前客製化上網卡可在哪些國家使用?',
    description:
      '我們目前在XX國家或地區提供服務，未來將會繼續增加新的國家或地區。',
  },
  {
    title: 'Q. 請問有支援語音通話及簡訊服務嗎?',
    description: '我們只提供流動數據服務，並不支援通話與簡訊服務。',
  },
  {
    title: 'Q. 支援熱點分享嗎?',
    description:
      '可以使用手機熱點分享，但無法於電腦或WIFI機上使用。遵照公平使用原則，一般建議維持在2人使用。',
  },
  {
    title: 'Q. 數據流量有限制嗎？',
    description:
      '我們提供無限數據，但請留意公平使用原則，建議您於需要高流量時盡量使用免費WIFI。',
  },
  {
    title: 'Q. 卡片是否可以退換貨?',
    description:
      '因為我們是客製化的卡片，下單後一旦開始製作，恕不接受取消或是退貨，下單前請務必慎重考量自身需求。',
  },
  {
    title: 'Q. 如果我在使用上有任何技術上問題，可以怎樣解決？',
    description: '您可以加入我們的LINE@線上客服，我們會有專人為您解決問題',
  },
];

class FAQContent extends React.Component {
  state = {
    stepIndex: 0,
  };

  render() {
    const { stepIndex } = this.state;
    let { lang } = this.props;
    let faq = lang === 'tw' ? QuestionArrayTw : QuestionArrayEn;
    return (
      <Wrapper>
        <Stepper activeStep={stepIndex} linear={false} orientation="vertical">
          {faq.map((ques, idx) => (
            <Step>
              <StepButton
                onClick={() => this.setState({ stepIndex: idx })}
              >
                {ques.title}
              </StepButton>
              <StepContent
                style={{
                  marginTop: '0px !important',
                  fontSize: 16,
                  color: '#5c9fb5',
                }}
              >
                <p>{ques.description}</p>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
`;

export default connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(FAQContent);
